import posthog from 'posthog-js';
import { useEffect } from 'react';

const usePostHog = () => {
  const posthogKey = process.env.NEXT_PUBLIC_POSTHOG_KEY;
  const posthogHost = process.env.NEXT_PUBLIC_POSTHOG_HOST;

  useEffect(() => {
    if (posthogKey && posthogHost) {
      console.log('Initializing PostHog', posthogKey, posthogHost);
      posthog.init(posthogKey, {
        api_host: posthogHost,
        person_profiles: 'identified_only',
        ip: true,
        debug: false,
      });
    }
  }, []);
};

export default usePostHog;
